import request from '@/api/request'


//PC企业端-通讯录列表
export function companyBookList(data) {
    return request({
        url: '/company/company/book/list',
        method: 'GET',
        params:data
    })
    
}

///company/company/book/info
//PC企业端-通讯录人员详情
export function companyBookInfo(data) {
    return request({
        url: '/company/company/book/info',
        method: 'GET',
        params:data
    })
    
}

///PC企业端-通讯录人员添加
export function companyBookAdd(data) {
    return request({
        url: '/company/company/book/add',
        method: 'POST',
        data
    })
}


//PC企业端-通讯录人员禁用
export function companyBookUpdate(data) {
    return request({
        url: '/company/company/book/update',
        method: 'POST',
        data
    })
}

//PC企业端-通讯录人员删除
export function companyBookDel(data) {
    return request({
        url: '/company/company/book/del',
        method: 'POST',
        data
    })
}











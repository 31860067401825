<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="详情"
    width="500px"
    
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">
       

       <el-form-item label="姓名" prop="alias_name">
         {{ info.alias_name }}
       </el-form-item>
    

       <el-form-item label="性别" prop="sex" >
         {{ info.sex==1?'男':info.sex==2?'女':'不公开' }}
       </el-form-item>



       <el-form-item label="手机号" prop="mobile" >
         {{ info.mobile }}
       </el-form-item>



       <el-form-item label="邮箱" prop="email" >
         {{ info.email }}
       </el-form-item>

       


       <el-form-item label="身份" prop="ident" >
         
         {{ info.ident }}
       </el-form-item>


     </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>

import { companyBookInfo} from "@/api/address/index";

export default {
  name:"addressEditDialog",

  data() {
    return {
      visible: false,
      info: {
        alias_name:"",
        sex: "",
        mobile: "",
        email: "",
        password: "",
        ident:"",
        company_user_type: "",
      },
      formData:{
        user_app_id:''
      }
     
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init(user_app_id) {
      this.visible = true;
      this.formData.user_app_id=user_app_id
      this.initInfo();
      
    },

   
    
    initInfo(){
      var that = this;
      companyBookInfo({"user_app_id":that.formData.user_app_id	}).then((response) => {
        if(response.code==200){
          that.info = response.data;
        }else{
          that.$message.warning(response.msg?response.msg:"获取信息失败");
        }
      });
    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>

<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="添加"
    width="500px"
    
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">
       

       <el-form-item label="姓名" prop="name">
         <el-input v-model="formData.name" ></el-input>
       </el-form-item>
    

       <el-form-item label="性别" prop="sex" >
         <el-select v-model="formData.sex" placeholder="请选择">
           <el-option label="不限" value="0"></el-option>
           <el-option label="男" value="1"></el-option>
           <el-option label="女" value="2"></el-option>
         </el-select>
       </el-form-item>



       <el-form-item label="手机号" prop="mobile" >
         <el-input  v-model="formData.mobile" ></el-input>
       </el-form-item>



       <el-form-item label="邮箱" prop="email" >
         <el-input   v-model="formData.email" ></el-input>
       </el-form-item>

       <el-form-item label="密码" prop="password" >
         <el-input   v-model="formData.password" ></el-input>
       </el-form-item>


       <el-form-item label="身份" prop="company_user_type" >
         <el-select v-model="formData.company_user_type" placeholder="请选择">
           <el-option label="督查" value="3"></el-option>
           <el-option label="客服" value="4"></el-option>
           <el-option label="普通人员" value="5"></el-option>
         </el-select>
       </el-form-item>


       <!--<div class="footer">
         <el-button size="medium" type="primary" @click="submit" >提交</el-button>
         <el-button size="medium" type="primary" @click="cancel" >关闭</el-button>
       </div>-->
     </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>


  </el-dialog>
</template>

<script>

import { companyBookAdd} from "@/api/address/index";

export default {
  name:"addressAddDialog",

  data() {
    return {
      visible: false,
      formData: {
        name:"",
        sex: "",
        mobile: "",
        email: "",
        password: "",
        company_user_type: "",
      },
       rules: {
        name: [
              {required: true, message: '请填写姓名', trigger: 'blur'}
          ],
          sex: [
              {required: true, message: '请选择性别', trigger: 'change'}
          ],
          mobile: [
              {required: true, message: '请填写手机号', trigger: 'blur'}
          ],

          email: [
              {required: true, message: '请填写邮箱', trigger: 'blur'}
          ],

          password: [
              {required: true, message: '请填写密码', trigger: 'blur'}
          ],
          company_user_type: [
              {required: true, message: '请选择角色', trigger: 'change'}
          ],
          
        
       },
       userList:[]
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init() {
      this.visible = true;
      
    },

   
    
    submit(){
      var that=this;
     

      this.$refs["form"].validate((valid) => {
          if (valid) {
            companyBookAdd(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'

                });
                that.$emit('refresh');
                that.visible = false;
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
